import styled from 'styled-components';
import {Form, InputGroup} from 'react-bootstrap';
import InputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';

export const ErrorStyled = styled.small`
    display: flex;
    color: #F63220;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    justify-content: end;
    margin-bottom: -10px;
`;

export const FormControlStyled = styled(Form.Control)`
    cursor: pointer;
    border-right-color: ${props => props.password ? '#FFFFFF !important' : ''}; 
    border-color: ${props => props.error ? 'red' : ''};
    color: ${props => props.error ? 'red' : props.readonly ? '#8696AC' : ''};
    background: ${props => props.readonly ? '#F4F5FB;' : ''};
`;

export const DatePickerStyled = styled(DatePicker)`
    cursor: pointer;
    border-right-color: ${props => props.password ? '#FFFFFF !important' : ''}; 
    border-color: ${props => props.error ? 'red' : ''};
    color: ${props => props.error ? 'red' : props.readonly ? '#8696AC' : ''};
    background: ${props => props.readonly ? '#F4F5FB;' : ''};
    width: 170% !important;
`;

export const IconStyled = styled(InputGroup.Text)`
    background-color: #FFFFFF;    
    border-color: ${props => props.error ? 'red' : ''};
    color: ${props => props.error ? 'red' : ''};

    img {
        cursor: pointer;
    }      

    img:hover{
        color: #0C1D59;
    } 
`;

export const InputGroupStyled = styled(InputMask)`
    border-color: ${props => props.error ? 'red' : ''};
    color: ${props => props.error ? 'red' : ''};
`;

export const InputMaskStyled = styled(InputMask)`
    border-color: ${props => props.error ? 'red' : ''};
    color: ${props => props.error ? 'red' : props.readonly ? '#8696AC' : ''};
    background: ${props => props.readonly ? '#F4F5FB;' : ''};
`;

export const ImgStyled = styled.img`
    position: absolute;
    right: 1rem;
    padding-top: 7px;
`;

export const LabelStyled = styled(Form.Label) `
    display: flex;
    justify-content: start;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0C1D59;

    small{
        margin-left: 4px;
        font-weight: 400;
        font-size: 15px;
        color: #8696AC;
    }
`;
