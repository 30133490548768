import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const accessToken = localStorage.getItem('access_token')

const base = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + accessToken
    }
})

const Api = {
    getDocTemporaryUrl(path) {
        const config = {
            params: { path }
        }
        return base.get('document/temporaryUrl', config)
    },

    getPublicScheduleByClinicId(clinicId){
        return base.get(`public-schedule/${clinicId}/getByClinicId`)
    },
    getPublicScheduleProfessional(professionalId){
        return base.get(`public-schedule-professional/${professionalId}`)
    },
    getPublicScheduleProfessionalsByClinicId(clinicId, view){
        return base.get(`public-schedule-professionals/${clinicId}/getByClinicId/${view}`)
    },
    getFilteredPublicScheduleProfessional(data){
        const config = {
            params: { ...data }
          }
        return base.get('public-schedule-professionals/getCalendarProfessionals', config)
    },
    getProfessionalClinicAppointments(clinicId, params) {
        return base.get(`appointments/${clinicId}/getScheduleAppointments`, { params: { ...params, clinicId } })
    },
    getClinicScheduleDaysConfig(clinicId) {
        return base.get(`clinics/${clinicId}/schedule/config/get-days`)
    },
    getAllOutages(clinicId) {
        return base.get(`clinics/${clinicId}/schedule/config/all-outages`)
    },

    createAppointment(data){
        return base.post('appointments/patientCreate', data)
    }, 

    getAllClinicHealthPlans(clinicId){
        return base.get(`clinicHealthPlan/${clinicId}/all`)
    },

    getAppointmentDetails(appointmentId) {
        return base.get(`/appointments/${appointmentId}/details`)
    },
    updateAppointmentStatus(data) {
        return base.put(`/appointments/updateStatus`, data)
    },
    getItemPrice(clinicId, type, itemId, healthPlanId, planId) {
        const config = {
          params: { type, itemId, healthPlanId, planId }
        }
        return base.get(`items/${clinicId}/price`, config)
    },

    getAttendance(attendanceId) {
        return base.get(`attendance/${attendanceId}`)
    },
    getAttendanceFiles(attendanceId, loadTemporaryUrl, notTypes) {
        const config = {
          params: { loadTemporaryUrl, notTypes }
        }
        return base.get(`attendance/${attendanceId}/files`, config)
    },
}   

export default Api;