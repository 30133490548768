import {Form} from 'react-bootstrap';
import { FormControlStyled, LabelStyled, DatePickerStyled } from './styles'
import Error from './error'
import {registerLocale } from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
registerLocale('ptBR', ptBR);

const Input = ({label, subLabel, type, placeholder, fieldValue, setValue, readonly, required, error, activeError}) => {

    return (
        <div>            
            <Form.Group>
                {
                    label &&                    
                    <LabelStyled>
                        {label}                    
                        {
                            subLabel &&                        
                            <small>{subLabel}</small>
                        } 
                    </LabelStyled>
                }
                {
                    type === 'date' ?
                        <DatePickerStyled
                            selected={fieldValue}
                            onChange={(date) => setValue(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText={placeholder}
                            customInput={<FormControlStyled />}
                            locale="ptBR"
                        /> 
                    :
                        <FormControlStyled 
                            required={required}
                            readOnly={readonly}
                            type={type} 
                            placeholder={placeholder} 
                            value={fieldValue}
                            onChange={(value) => !readonly ? setValue(value.target.value) : ''}
                            error={error}
                        />
                }
                {
                    activeError &&                 
                    <Error message={error === 'erro' ? '' : error} />
                }
            </Form.Group>
        </div>
    )
}

export default Input;